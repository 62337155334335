import { Grid, ResponsiveText } from '@eventbrite/wagtail-components';
import React from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import LazyloadImage from '../../sharedComponents/LazyloadImage';
import { BaseModuleProps, ITestimonialBlock } from '../types';

interface IMultipleTestimonialProps {
    headline?: string;
    testimonials: Array<ITestimonialBlock>;
    isContentFromContentful?: boolean;
    useH1?: boolean;
}

const responsiveHeaderProps = {
    base: {
        fontSize: 52,
        lineHeight: 64,
    },
    small: {
        fontSize: 32,
        lineHeight: 40,
    },
};

const responsiveQuoteProps = {
    base: {
        fontSize: 30,
        lineHeight: 40,
    },
    small: {
        fontSize: 24,
        lineHeight: 32,
    },
};

const responsiveAttributionProps = {
    base: {
        fontSize: 18,
        lineHeight: 24,
    },
    small: {
        fontSize: 14,
        lineHeight: 20,
    },
};

export const MultipleTestimonial: React.FC<
    IMultipleTestimonialProps & BaseModuleProps
> = ({ headline, testimonials, variant, useH1 = false }) => {
    const baseOptions = { variant };
    const theme = useTheme();
    const [textClass] = theme.generateStyles('text', baseOptions);
    const [backgroundClass] = theme.generateStyles('background', baseOptions);
    const moduleOverrideClassByTheme = `mlp-multiple-testimonial__${theme.theme}`;

    return (
        <section className={backgroundClass}>
            <div
                className={`multiple-testimonial__container module-landing-page__module-container-content ${moduleOverrideClassByTheme}`}
            >
                {headline && (
                    <header>
                        <ResponsiveText
                            {...responsiveHeaderProps}
                            component={useH1 ? 'h1' : 'h2'}
                            className={`${textClass} mutiple-testimonial-headline`}
                        >
                            {headline}
                        </ResponsiveText>
                    </header>
                )}
                <Grid
                    base={{
                        gap: 40,
                        columnCount: 3,
                    }}
                    medium={{
                        gap: 20,
                        rowGap: 50,
                        columnCount: 2,
                    }}
                    small={{
                        gap: 40,
                        columnCount: 1,
                    }}
                    className="multiple-testimonial__testimonials"
                >
                    {testimonials.map(({ quote, attribution, image }, idx) => {
                        return (
                            <article
                                key={`${attribution}-${idx}`}
                                className="multiple-testimonial__content"
                            >
                                <ResponsiveText
                                    {...responsiveQuoteProps}
                                    component="q"
                                    className={`multiple-testimonial__quote ${textClass}`}
                                >
                                    {quote}
                                </ResponsiveText>
                                <ResponsiveText
                                    {...responsiveAttributionProps}
                                    component="cite"
                                    className={`multiple-testimonial__attribution ${textClass}`}
                                >
                                    {image && (
                                        <span className="multiple-testimonial__avatar">
                                            <LazyloadImage
                                                image={image.imageUrl}
                                                alt={image.altText}
                                            />
                                        </span>
                                    )}
                                    <span>{attribution}</span>
                                </ResponsiveText>
                            </article>
                        );
                    })}
                </Grid>
            </div>
        </section>
    );
};
